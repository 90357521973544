// import logo from './images/logo.webp';
import './App.css';

function App() {
  return (
    <div className="flex flex-col items-center justify-end max-h-screen h-screen w-full">
      <div className="relative h-full w-full bg-[url('./images/logo.webp')] bg-contain bg-center bg-no-repeat">
        {/* <img className='h-4/5 max-h-screen' src={logo} /> */}
        <div class="absolute top-[48%] right-0 bottom-0 left-0 overflow-x-hidden">
          <div className="flex uppercase tw-text-bold animate-marquee whitespace-nowrap text-xl sm:text-lg">
            <div className="mr-4">Fishbate Studio is a collaboration between Emma Banks and Gaby Javitt.</div>
            <div className="mr-4">Fishbate Studio is a collaboration between Emma Banks and Gaby Javitt.</div>
            <div className="mr-4">Fishbate Studio is a collaboration between Emma Banks and Gaby Javitt.</div>
            <div className="mr-4">Fishbate Studio is a collaboration between Emma Banks and Gaby Javitt.</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-between w-full py-8 px-16 sm:flex-row">
        <a className="hover:text-red-600 hover:animate-spin" href="https://instagram.com/fishbatestudio">instagram</a>
        <a className="hover:text-blue-600 hover:animate-spin" href="mailto:hello@fishbatestudio.com">contact</a>
        <p className="hover:text-yellow-600 hover:animate-spin">© fishbate studio 2022</p>
      </div>
    </div>
  );
}

export default App;
